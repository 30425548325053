<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-title class="py-1">
        {{ title }}
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="mt-4">
        {{ text }}
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="darken-1" text @click="onClose()"> Close </v-btn>

        <v-btn color="green darken-1" @click="onConfirm()"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmLogoutDialog",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },

  methods: {

    /**
     * Notify the parent that the dialog is to be closed
     */
    onClose() {
      this.$emit("close");
    },

    /**
     * Notify the parent that the user really wants to log out
     */
    onConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>