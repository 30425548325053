<template>
  <v-overlay :value="snackbar">
    <v-snackbar v-model="snackbar" :color="color" elevation="24" timeout="-1" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="onCloseSnackbar"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    snackbar: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Error",
    },
    color: {
      type: String,
      default: "error",
    },
  },
  methods: {

    /**
     * Notify the parent that the message toast is closed
     */
    onCloseSnackbar: function (oEvent) {
      this.$emit("close");
    },
  },
};
</script>