<template>
  <v-card>
    <v-app-bar dark fixed width="100%" class="ma-0 pa-0">
      <v-row no-gutters justify="space-between" @click="onClickDate()">
        <v-col cols="5">
          <v-row justify="center">
            <label>{{ from_day }}</label>
          </v-row>
          <v-row justify="center">
            <label>{{ from_date }}</label>
          </v-row>
        </v-col>

        <v-col cols="2">
          <v-icon>mdi-minus-thick</v-icon>
        </v-col>

        <v-col cols="5">
          <v-row justify="center">
            <label>{{ to_day }}</label>
          </v-row>
          <v-row justify="center">
            <label>{{ to_date }}</label>
          </v-row>
        </v-col>
      </v-row>

      <router-link to="/" custom v-slot="{ navigate }">
        <v-btn plain icon @click="navigate">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </router-link>
    </v-app-bar>

    <range-selector-popup
      ref="dateSelectorDialog"
      :dialog="dateSelectorDialog"
      :range="range"
      @close="onCloseRangeSelectorDialog"
      @select="onSelectRangeSelectorDialog"
    >
    </range-selector-popup>

    <v-container class="mt-14 mb-16">
      <v-card>
        <v-simple-table class="appointment-details-table">
          <template v-slot:default>
            <tbody>
              <tr v-for="day in dailyStats" :key="day.date">
                <td>{{ day.date }}</td>
                <td>{{ getFormattedMinutes(day.minutes) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>

    <v-footer padless fixed>
      <v-col class="text-center" cols="12">
        <v-simple-table class="appointment-details-table sum-table">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{ from_date }} - {{ to_date }}</td>
                <td>{{ getFormattedMinutes(sum_hours) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-footer>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import SelectFunctions from "../select/SelectFunctions";
import RangeSelectorPopup from "../components/RangeSelectorPopup.vue";
var isoWeek = require("dayjs/plugin/isoWeek");
export default {
  components: {
    RangeSelectorPopup,
  },

  name: "Statistics",

  props: {},

  data() {
    return {
      dateSelectorDialog: false,
      range: this.getDefaultRange(),
      dailyStats: [],
    };
  },

  computed: {
    from_date() {
      return dayjs(this.range[0]).format("DD/MM/YYYY") || "error";
    },
    from_day() {
      return dayjs(this.range[0]).format("dddd") || "error";
    },
    to_date() {
      return dayjs(this.range[1]).format("DD/MM/YYYY") || "error";
    },
    to_day() {
      return dayjs(this.range[1]).format("dddd") || "error";
    },

    sum_hours() {
      return this.dailyStats.reduce(function (prev, curr) {
        return prev + Number(curr.minutes);
      }, 0);
    },
  },

  methods: {

    /**
     * Create a worked hour display format
     * based on the worked minutes
     */
    getFormattedMinutes(sMinutes) {
      var iMinutes = Number(sMinutes);
      var iHours = Math.floor(iMinutes / 60);
      var iRemMinutes = iMinutes % 60;

      return iHours.toString() + " hrs " + iRemMinutes.toString() + " mins";
    },

    /**
     * Calculate the current week
     */
    getDefaultRange() {
      dayjs.extend(isoWeek);
      var oNow = new Date();
      return [dayjs(oNow).startOf("isoWeek"), dayjs(oNow).endOf("isoWeek")];
    },

    /**
     * Open dialog //rangeSelector//
     */
    onClickDate() {
      this.dateSelectorDialog = true;
    },

    /**
     * Close dialog //rangeSelector//
     * do not change anything
     */
    onCloseRangeSelectorDialog() {
      this.dateSelectorDialog = false;
    },

    /**
     * Close dialog //rangeSelector//
     * Update the selected range
     */
    onSelectRangeSelectorDialog(aRange) {
      this.dateSelectorDialog = false;
      this.range = [new Date(aRange[0]), new Date(aRange[1])];

      this.loadWorkedHoursRange(this.range[0], this.range[1]);
    },


    /**
     * Create a structure suitable for the UI
     * based on the worked hours data from the BE
     */
    getFormattedDailyStats(from_date, to_date, dates) {
      var oWorked = this.getWorkedDaysObject(dates);

      var aDays = [];
      var oStart = dayjs(from_date).startOf("day");
      var oEnd = dayjs(to_date).startOf("day");

      for (var oDay = oStart; oDay <= oEnd; oDay = dayjs(oDay).add(1, "day")) {
        var sDate = dayjs(oDay).format("DD/MM/YYYY");
        var oNewEntry = oWorked[sDate]
          ? oWorked[sDate]
          : { date: sDate, minutes: 0 };
        aDays.push(oNewEntry);
      }
      return aDays;
    },

    /**
     * Create an object based on the days
     * from the worked hours data from the BE
     */
    getWorkedDaysObject(dates) {
      return dates.reduce(function (oPrev, oDay) {
        var sDate = dayjs(oDay.date).format("DD/MM/YYYY");
        oPrev[sDate] = {
          date: sDate,
          minutes: oDay.minutesworked,
        };
        return oPrev;
      }, {});
    },

    /**
     * Load the worked hours statistics for the selected period.
     * Use the logged in user's session to get user-specific data
     */
    async loadWorkedHoursRange(from_date, to_date) {
      var sFromDate = dayjs(from_date).format("YYYY-MM-DD");
      var sToDate = dayjs(to_date).format("YYYY-MM-DD");

      var response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "schedule/timeworked/" + sFromDate + "/" + sToDate,
        { credentials: "include" }
      );

      switch(response.status) {
        case 200:
          var data = await response.json();
          this.dailyStats = this.getFormattedDailyStats(from_date, to_date, data.dates);
          break;
        default:
          this.dailyStats = this.getFormattedDailyStats(from_date, to_date, []);
      }
    },
  },


  /**
   * Initially, load the statistics for the current week
   */
  mounted() {
    this.loadWorkedHoursRange(this.range[0], this.range[1]);
  },
};
</script>

<style>
</style>