<template>
  <div>
    <v-app-bar
      dark
      height="30px"
    >
      <v-btn
        icon
        @click="onClickQuickScan"
      >
        <v-icon>mdi-qrcode-scan</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="onClickLogOut"
      >
        <v-icon>mdi-power-standby</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container
      fluid
      fill-height
    >
      <nav-date-bar
        class="nav-date-bar"
        :disableArrowButton="disableThisButton"
        :displayDate="selectedDate"
        @dateChange="changeDate"
        @click="onOpenDaySelectorDialog"
        @disableButton="disableButtonAction"
      >
      </nav-date-bar>

      <day-selector-popup
        ref="dateSelectorDialog"
        :dialog="dateSelectorDialog"
        :selectedDate="selectedDate"
        @close="onCloseDaySelectorDialog"
        @select="onSelectDaySelectorDialog"
        @disableButton="disableButtonAction"
      >
      </day-selector-popup>

      <appointment-details-popup
        ref="appointmentDetailDialog"
        :dialog="appointmentDetailDialog"
        :appointment="selectedAppointment"
        @close="onCloseAppointmentDetailDialog"
        @statusChange="onChangeAppointmentStatus"
      >
      </appointment-details-popup>

      <calendar-container
        ref="calendarContainer"
        @showEventDetails="showEventDetails"
        :appointments="calendarEvents"
        :date="selectedDate"
      >
      </calendar-container>

    </v-container>

    <v-row>
      <v-col
        cols='12'
        xs='12'
        md='12'
      >
        <span>
          version:{{appVersion}}
        </span>

      </v-col>
    </v-row>
    <router-link
      to="/Statistics"
      custom
      v-slot="{ navigate }"
    >
      <v-btn
        class="mb-80 floating-button"
        fab
        large
        absolute
        bottom
        color="blue"
        right
        @click="navigate"
      >
        <v-icon color="white">mdi-sigma</v-icon>
      </v-btn>
    </router-link>

    <error-snackbar
      :snackbar="error.status"
      :text="error.text"
      :color="error.color"
      @close="onCloseSnackbar"
    >
    </error-snackbar>

    <qr-dialog
      :dialog="quickScanDialog"
      @qrCodeRead="onQuickScanRead"
      @qrCodeClose="onQuickScanClose"
    >
    </qr-dialog>

    <confirm-logout-dialog
      :dialog="confirmLogoutDialog"
      title="Log out"
      text="Do you want to log out?"
      @close="onCancelLogOut"
      @confirm="onLogOut"
    >
    </confirm-logout-dialog>
    <decision-dialog
      :dialog="decision.dialog"
      :title="decision.title"
      :text="decision.text"
      @close="decision.close"
      @confirm="decision.confirm"
    >
    </decision-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";

import { version } from '../../package'


import CalendarContainer from "../components/CalendarContainer.vue";
import NavDateBar from "../components/NavDateBar.vue";
import SelectFunctions from "../select/SelectFunctions";
import DaySelectorPopup from "../components/DaySelectorPopup.vue";
import AppointmentDetailsPopup from "../components/AppointmentDetailsPopup.vue";
import ErrorSnackbar from "../components/ErrorSnackbar.vue";
import ConfirmLogoutDialog from '../components/ConfirmLogoutDialog.vue';
import QrDialog from '../components/QrDialog.vue';
import DecisionDialog from '../components/DecisionDialog.vue';

export default {
  components: {
    CalendarContainer,
    NavDateBar,
    DaySelectorPopup,
    AppointmentDetailsPopup,
    ErrorSnackbar,
    ConfirmLogoutDialog,
    QrDialog,
    DecisionDialog
  },

  data ()
  {
    return {
      selectedDate: new Date(),
      calendarEvents: [],
      selectedAppointment: {},
      disableThisButton: false,
      dateSelectorDialog: false,
      appointmentDetailDialog: false,
      error: {
        status: false,
        text: "",
        color: "",
      },
      quickScanDialog: false,
      confirmLogoutDialog: false,
      decision: {
        dialog: false,
        title: '',
        text: '',
        close: function () { },
        confirm: function () { },
      },
      appVersion: version
    };
  },

  computed: {},

  methods: {
    /**
     * Tasks when the date is changed in any way
     *  - Set selected date
     *  - Load the appointments for the new day
     *  - Set the date pickers default value
     */
    changeDate (newDate)
    {
      var oDate = new Date(newDate);
      this.selectedDate = oDate;

      this.loadAppointments(oDate);
      this.$refs.dateSelectorDialog.setPicker(oDate);
    },

    /**
     * Load the appointments for the selected day.
     * Use the logged in user's session to get user-specific data
     */
    async loadAppointments (date)
    {
      var sDate = date.toISOString().split("T")[0];

      var response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "schedule/" + sDate,
        { credentials: "include" }
      );

      switch (response.status)
      {
        case 200:
          var data = await response.json();
          this.calendarEvents = this.formatAppointments(data);
          return true;
        default:
          this.onOpenSnackbar("Error while reading data", "error");
          return false;
      }
    },

    /**
     * Format the array of appointments to front-end compatible objects
     */
    formatAppointments (aAppointments)
    {
      return aAppointments.map(function (oApp)
      {
        return {
          id: oApp.sh_syskey,
          customer: oApp.sh_cname,
          task: oApp.sh_shortdesc,
          description: oApp.sh_taskdesc,
          date: dayjs(oApp.x_date_formatted).format("DD/MM/YYYY"),
          address: {
            add1: oApp.cn_add1,
            add2: oApp.cn_add2,
            add3: oApp.cn_add3,
            add4: oApp.cn_add4,
            pcode: oApp.cn_pcode,
          },
          start: dayjs(oApp.x_fromtime_formatted).format("YYYY-MM-DD HH:mm"),
          end: dayjs(oApp.x_totime_formatted).format("YYYY-MM-DD HH:mm"),
          start_time: oApp.ti_fromtime,
          end_time: oApp.ti_totime,
          duration: oApp.ti_time,
          status: oApp.x_status,
          active: Boolean(oApp.x_active),
          checkin_time: oApp.cl_actftime,
          checkout_time: oApp.cl_actttime,
        };
      });
    },

    /**
     * Open dialog //selectDay//
     */
    onOpenDaySelectorDialog ()
    {
      this.dateSelectorDialog = true;
    },

    /**
     * Close dialog //selectDay//
     * set the default value for the picker
     */
    onCloseDaySelectorDialog ()
    {
      this.$refs.dateSelectorDialog.setPicker(this.selectedDate);
      this.dateSelectorDialog = false;
    },


    /**
     * Close dialog //selectDay//
     * Update the display day
     */
    onSelectDaySelectorDialog (oDay)
    {
      this.dateSelectorDialog = false;
      this.changeDate(oDay);
    },

    disableButtonAction (button)
    {

      this.disableThisButton = button;
    },

    /**
     * Open dialog //appointmentDetails//
     * with the selected appointment
     */
    showEventDetails (oEvent)
    {
      this.selectedAppointment = oEvent;
      this.appointmentDetailDialog = true;
    },

    /**
     * Called from the child
     * close dialog //appointmentDetails//
     */
    onCloseAppointmentDetailDialog ()
    {
      this.appointmentDetailDialog = false;
    },

    /**
     * Open message toast skeleton
     */
    onOpenSnackbar (sText, sColor)
    {
      this.error.status = true;
      this.error.text = sText;
      this.error.color = sColor;
    },

    /**
     * Close the status message toast 
     */
    onCloseSnackbar ()
    {
      this.error.status = false;
    },

    /**
     * Called from the //appointmentDetails// child
     * When an action took place, but we don't synchronize the data
     */
    async onChangeAppointmentStatus (sStatus, sId)
    {
      var oEvent = this.calendarEvents.find((o) => o.id === sId);
      oEvent.status = sStatus;

      await this.loadAppointments(this.selectedDate);
      var sSelectedId = this.selectedAppointment.id;
      this.selectedAppointment = this.calendarEvents.find(x => x.id === sSelectedId);
    },

    /**
     * Open the quick scan dialog
     * Allow the user to log in to appointments not assigned to them.
     */
    onClickQuickScan ()
    {
      var that = this;

      this.decision = {
        dialog: true,
        title: 'Quick Scan',
        text: 'Only use this function if the call is not already on your calendar.',
        close: function ()
        {
          that.decision.dialog = false;
        },
        confirm: function ()
        {
          that.decision.dialog = false;
          that.quickScanDialog = true;
        },
      };
    },

    onQuickScanRead (sCode)
    {
      this.quickScanDialog = false;
      this.qrCheck(sCode);
    },

    onQuickScanClose ()
    {
      this.quickScanDialog = false;
    },

    /**
     * Send the read QRcode to the BE for processing
     * include the session data too
     */
    async qrCheck (sCode)
    {
      var oParams = {
        sh_csyskey: sCode,
      };

      var response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "schedule/" + "quickScan",
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(oParams),
        }
      );

      switch (response.status)
      {
        case 200:
          this.onOpenSnackbar("Success", "success");
          break;
        case 400:
          this.onOpenSnackbar("Invalid code", "error");
          break;
        default:
          this.onOpenSnackbar("Unknown error", "error");
      };
    },

    /**
     * Open confirmation window to see if the user really wants to log out
     */
    onClickLogOut ()
    {
      this.confirmLogoutDialog = true;
    },


    /**
     * Do not log out.
     * Close the confirmation window.
     */
    onCancelLogOut ()
    {
      this.confirmLogoutDialog = false;
    },

    /**
     * Call the backend to delete the session
     * Redirect to the login page
     */
    async onLogOut ()
    {
      var response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "schedule/" + "logout",
        { credentials: "include" }
      );

      switch (response.status)
      {
        case 200:
          this.$router.push({ path: "Login" });
          break;
        default:
          this.onOpenSnackbar("Error while logging out", "error");
      }
    },
  },

  /**
   * Initially, load the user's appointments for today
   */
  mounted ()
  {
    this.loadAppointments(this.selectedDate);
  },
};
</script>

<style scoped>
.nav-date-bar {
  align-items: center;
}
</style>
