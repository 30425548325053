<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-row justify="center">
        <v-date-picker class="pt-4" :no-title="true" range v-model="selectedRange"></v-date-picker>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="darken-1" text @click="onClose()"> Close </v-btn>

        <v-btn color="green darken-1" @click="onSelectRange()"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import dayjs from "dayjs";

export default {
  name: "RangeSelectorPopup",
  data() {
    return {
      selectedRange: [
        dayjs(this.range[0]).format("YYYY-MM-DD"),
        dayjs(this.range[1]).format("YYYY-MM-DD")
      ]
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },

    range: {
      type: Array,
      default: () => [new Date(), new Date()],
    },
  },

  mounted() {
  },

  computed: {
  },

  methods: {
    onClose() {
      this.$emit("close");

      this.selectedRange = [
        dayjs(this.range[0]).format("YYYY-MM-DD"),
        dayjs(this.range[1]).format("YYYY-MM-DD")
      ];
    },
    onSelectRange() {
      this.$emit("select", this.selectedRange);
    },
  },
};
</script>
