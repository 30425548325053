<template>
  <v-dialog
    v-model="dialog"
    max-width="290"
  >
    <v-card>
      <v-row justify="center">
        <v-date-picker
          class="pt-4"
          :no-title="true"
          v-model="picker"
        ></v-date-picker>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="darken-1"
          text
          @click="onClose()"
        > Close </v-btn>

        <v-btn
          color="green darken-1"
          @click="onSelectDay()"
        > OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "DaySelectorPopup",
  data ()
  {
    return {
      picker: dayjs(this.selectedDate).format("YYYY-MM-DD"),
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },

    selectedDate: {
      type: Date,
      default: () => new Date(),
    },
  },

  methods: {
    /**
     * Called from the parent,
     * when the date is changed with the step buttons
     */
    setPicker (oDate)
    {
      this.picker = oDate.toISOString().substr(0, 10);
    },

    warningAction ()
    {
      this.$notify({
        group: "global",
        type: "warning",
        title: "Selected date out of range",
        text: "The selected date is out of the range, furthest day is shown.",
      });
    },

    /**
     * Let the parent know we want to close this dialog
     */
    onClose ()
    {
      this.$emit("close");
    },

    /**
     * Let the parent know we selected a new date
     */
    onSelectDay ()
    {
      var button;

      if (process.env.VUE_APP_DAYS_AHEAD)
      {
        var limitDate = dayjs().add(process.env.VUE_APP_DAYS_AHEAD, "day");

        if (dayjs(this.picker) > limitDate)
        {
          this.warningAction();

          button = true;
          this.$emit("disableButton", button);
          this.$emit("select", limitDate);
          return;
        } else if (_.isEqual(limitDate, dayjs(this.picker)))
        {
          button = true;
          console.log("hello")
          this.$emit("disableButton", button);
          this.$emit("select", limitDate);
          return;
        }
      } else
      {
        var limitDate = dayjs().add(process.env.VUE_APP_DAYS_AHEAD_DEFAULT, "day");

        if (dayjs(this.picker) > limitDate)
        {
          this.warningAction();

          button = true;
          this.$emit("disableButton", button);
          this.$emit("select", limitDate);
          return;
        } else if (_.isEqual(limitDate, dayjs(this.picker)))
        {
          button = true;
          this.$emit("disableButton", button);
          this.$emit("select", limitDate);
          return;
        }
      }

      button = false;
      this.$emit("disableButton", button);
      this.$emit("select", this.picker);
      return;
    },
  },
};
</script>
