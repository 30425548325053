<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay max-width="100%">
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>QR Code reader</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn icon @click="onCloseQRDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
        <v-card-text class="pa-0">
        <v-container fill-height fluid class="pa-0">
          <qr-code-stream
            v-if="dialog"
            @decode="onDecode"
            style="height: calc(100% - 56px); width: 100%"
          >
          </qr-code-stream>
        </v-container>
      </v-card-text>

      
    </v-card>
  </v-dialog>
</template>

<script>
import {QrcodeStream} from "vue-qrcode-reader";

export default {
  name: "QrDialog",
  components: {
    "qr-code-stream": QrcodeStream
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
  methods: {

    /**
     * Notify the parent that a QRcode is read
     */
    onDecode(sCode) {
      this.$emit("qrCodeRead", sCode);
    },

    /**
     * Notify the parent to close this dialog
     */
    onCloseQRDialog() {
      this.$emit("qrCodeClose");
    }
  },
};
</script>
