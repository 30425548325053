<template>
  <v-row
    no-gutters
    justify="space-between"
  >
    <v-col cols="3">
      <v-btn
        fab
        small
        color="blue"
        @click="decreaseDate()"
      >
        <v-icon
          x-large
          color="white"
        >mdi-menu-left</v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="6"
      @click="onClickDate()"
    >
      <v-row justify="center">
        <label>{{ formattedDay }}</label>
      </v-row>
      <v-row justify="center">
        <label>{{ formattedDate }}</label>
      </v-row>
    </v-col>
    <v-col cols="3">
      <v-btn
        fab
        small
        color="blue"
        @click="increaseDate()"
        :disabled="disableArrowButton"
      >
        <v-icon
          x-large
          color="white"
        >mdi-menu-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from "dayjs";
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
export default {
  name: "NavDateBar",
  props: {
    displayDate: {
      type: Date,
      default: () => new Date(),
    },
    disableArrowButton: {
      type: Boolean,
      default: false,
    },
  },

  mounted ()
  {
    this.daysAhead = Number(process.env.VUE_APP_DAYS_AHEAD) || 7;
    console.log("Mounted : days ahead : ", this.daysAhead);
  },

  computed: {
    formattedDate ()
    {
      return dayjs(this.displayDate).format("DD/MM/YYYY") || "error";
    },
    formattedDay ()
    {
      return dayjs(this.displayDate).format("dddd") || "error";
    },
  },

  methods: {
    decreaseDate ()
    {
      var newDate = dayjs(this.displayDate).subtract(1, "day");
      var button = false;
      this.$emit("disableButton", button);
      this.$emit("dateChange", newDate);
    },
    increaseDate ()
    {
      var newDate = dayjs(this.displayDate).add(1, "day");
      var limit = this.setLimitDate(this.selectedDate);
      var buttonLimit = this.setPreLimitDate(this.selectedDate);

      if (dayjs(this.displayDate).isSameOrAfter(buttonLimit))
      {
        var button = true;
        this.$emit("disableButton", button);
      }

      if (dayjs(this.displayDate).isSameOrAfter(limit))
      {
        return;
      }
      this.$emit("dateChange", newDate);
    },

    onClickDate ()
    {
      this.$emit("click");
    },

    setLimitDate (selectedDate)
    {
      if (this.daysAhead)
      {
        selectedDate = dayjs(dayjs().format('YYYY-MM-DD')).add(this.daysAhead, "day");

        return selectedDate;
      } else
      {
        selectedDate = dayjs().add(
          this.daysAhead,
          "day"
        );
        return selectedDate;
      }
    },

    setPreLimitDate (selectedDate)
    {
      if (this.daysAhead)
      {
        selectedDate = dayjs(dayjs().format('YYYY-MM-DD')).add(this.daysAhead, "day");
        var limitDate = dayjs(selectedDate).subtract("1", "day");
        return limitDate;
      } else
      {
        selectedDate = dayjs().add(
          this.daysAhead,
          "day"
        );
        var limitDate = dayjs(selectedDate).subtract("1", "day");
        return limitDate;
      }
    },
  },

  data: () =>
  {

    return {
      daysAhead: 7,
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
