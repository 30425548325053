var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-calendar',{ref:"calendar",staticStyle:{"height":"calc(100vh - 160px)"},attrs:{"start":_vm.displayDate,"color":"primary","type":"day","interval-height":"90","hide-header":true,"events":_vm.appointments,"event-color":_vm.getEventColor},on:{"click:event":_vm.showEvent},scopedSlots:_vm._u([{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
return [(_vm.$refs.calendar && date === week[0].date)?_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY })},[_c('div',[_vm._v(" "+_vm._s(_vm.$refs.calendar.times.now.time)+" ")])]):_vm._e()]}},{key:"day-header",fn:function(ref){
var present = ref.present;
return [(present)?[_vm._v(" Today ")]:_vm._e()]}},{key:"event",fn:function(ref){
var event = ref.event;
return [_c('p',{staticClass:"calendar-event-label left-aligned"},[_c('em',[_vm._v(_vm._s(event.start_time)+" ")]),_c('em',[_vm._v(_vm._s(event.customer))]),_vm._v(": "+_vm._s(event.task)+" ")]),_c('p',{staticClass:"calendar-event-label left-aligned"},[_vm._v(" "+_vm._s(event.address.add1)+", "+_vm._s(event.address.pcode)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }